<template>
  <custom-collapse
    class="card print-trip"
    :headerClass="{
      'print-trip-header': true,
      'has-cancelled-task': hasCancelledTask
    }"
    :open.sync="isLocalOpen">
    <template #header>
      <div class="print-trip-info">
        <div class="print-trip-title">
          {{ title }}
        </div>
        <div class="print-trip-subtitle">
          {{ subtitle }}
        </div>
      </div>
      <a
        class="select-all"
        @click.stop="selectAll">
        {{ $t('print.selectAllLabel') }}
      </a>
    </template>
    <div
      v-if="trip.tasks.length > 0"
      class="card-content">
      <print-trip-task
        v-for="task in trip.tasks"
        :key="`print_trip_task_${task.id}`"
        :ref="`trip_${trip.tripId}_${type}_task_${task.id}`"
        :task="task"
        :checkedTasks.sync="localCheckedTasks"/>
    </div>
  </custom-collapse>
</template>

<script>
import { formatTimeFromDate } from '@js/utils'

const CustomCollapse = () => import('@components/CustomCollapse')
const PrintTripTask = () => import('./PrintTripTask')

export default {
  name: 'print-trip',
  components: {
    CustomCollapse,
    PrintTripTask
  },
  props: {
    trip: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: null
    },
    isOpen: {
      type: Boolean,
      default: true
    },
    checkedTasks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isLocalOpen: this.isOpen
    }
  },
  computed: {
    hasCancelledTask() {
      return this.trip.tasks.some(task => task.isCancelled)
    },
    title() {
      return this.$t('print.trip.title', { specialist: this.trip.fulfilmentUserName, tripNum: this.trip.tripId })
    },
    subtitle() {
      const leaveBy = formatTimeFromDate(this.trip.leaveBy)
      return this.$t('print.trip.subtitle', { timeLabel: leaveBy, numTasksLabel: this.numTasksLabel })
    },
    numTasksLabel() {
      const tasksCount = this.trip.tasks.length
      return this.$tc('print.trip.numTasksLabel', tasksCount, { count: tasksCount })
    },
    localCheckedTasks: {
      get() {
        return this.checkedTasks
      },
      set(value) {
        this.$emit('update:checkedTasks', value)
      }
    },
    confirmedTaskIds() {
      return this.trip.tasks.filter(task => !task.isCancelled).map(task => task.id)
    }
  },
  watch: {
    isOpen: {
      handler() {
        if (this.isOpen) {
          this.isLocalOpen = true
        }
      }
    },
    isLocalOpen: {
      handler() {
        if (this.isLocalOpen) {
          // expand all tasks when expanding trip
          this.openTasks()
        }
      }
    }
  },
  methods: {
    selectAll() {
      const uncheckedTripTasks = this.confirmedTaskIds.filter(taskId => !this.localCheckedTasks.includes(taskId))
      if (uncheckedTripTasks.length > 0) {
        this.localCheckedTasks = this.localCheckedTasks.concat(uncheckedTripTasks)
      } else {
        this.localCheckedTasks = this.localCheckedTasks.filter(taskId => !this.confirmedTaskIds.includes(taskId))
      }
      this.open()
    },
    open() {
      this.isLocalOpen = true

      // expand all tasks, even when trip is already expanded
      this.openTasks()
    },
    openTasks() {
      this.trip.tasks.forEach((task) => {
        this.$refs[`trip_${this.trip.tripId}_${this.type}_task_${task.id}`][0].open()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.print-trip {
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: $space-s;
  }

  &::v-deep .custom-collapse-header.print-trip-header {
    padding: $space-s;
    font-weight: normal;
    color: $white;
    background-color: $secondary;
    @extend %body;

    &.has-cancelled-task {
      background-color: $danger;
    }

    .toggle-collapse-icon {
      position: relative;
      top: -10px;
    }
  }

  .print-trip-info {
    flex-grow: 1;

    .print-trip-title {
      @extend %display_medium;
    }
  }

  .select-all {
    font-weight: bold;
    color: $grey-light;
  }

  .card-content {
    background-color: $white;
  }
}
</style>
